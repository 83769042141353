import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import About from '../components/about';
import Seo from '../components/seo';
import Header from '../components/header';
import Mission from '../components/mission';
import Collections from '../components/collections';
import Roadmap from '../components/roadmap';
import Team from '../components/team';

const IndexPage = () => (
  <Layout>
    <Seo title="Cheeky Unts" />
    <Header />

    <About />
    <Roadmap />
    <Mission />
    <Collections />
    <Team />
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.shape({
    hostname: PropTypes.string.isRequired,
  }).isRequired,
};

export default IndexPage;
