import * as React from 'react';

import JasonImage from '../images/team/jason.webp';
import MattImage from '../images/team/matt.webp';
import LanceImage from '../images/team/lance.webp';
import GauravImage from '../images/team/gaurav.webp';
import KeanenImage from '../images/team/keanen.webp';
import BradfordImage from '../images/team/bradford.webp';

import './team.scss';

const Team = () => (
  <section className="team" id="team">
    <div className="team__container">
      <h1>Team Unt</h1>

      <p>
        These are the unts behind the Unts. Cheeky Unts shares founders with
        Bitgem and a few ring-ins who set out to create fun art, give back to
        great causes and build a sustainable and scalable business model.
      </p>
      <div className="team__list">
        <div className="team__member">
          <img src={JasonImage} alt="" />
          <h3>Jason (King Unt)</h3>
          <p>Australia</p>
          <p>Project Lead</p>
          <br />
          <p>
            <a href="https://twitter.com/CheekyUnts" target="_new">
              @CheekyUnts
            </a>
          </p>
        </div>
        <div className="team__member">
          <img src={MattImage} alt="" />
          <h3>Matt (Pixel Unt)</h3>
          <p>Germany</p>
          <p>Lead Artist</p>
          <br />
          <p>
            <a href="https://sketchfab.com/bitgem/" target="_new">
              Bitgem 3D
            </a>
          </p>
        </div>
        <div className="team__member">
          <img src={LanceImage} alt="" />
          <h3>Lance (Dev Unt)</h3>
          <p>United States</p>
          <p>Lead Dev</p>
          <br />
          <p>
            <a href="https://lancesnider.com/" target="_new">
              Lance Snider
            </a>
          </p>
        </div>
        <div className="team__member">
          <img src={GauravImage} alt="" />
          <h3>Gaurav (Py Unt)</h3>
          <p>Australia</p>
          <p>ML Engineer</p>
          <br />
          <p>
            <a href="https://twitter.com/gaurav_aror" target="_new">
              @gaurav_aror
            </a>
          </p>
        </div>
        <div className="team__member">
          <img src={KeanenImage} alt="" />
          <h3>Keanen (Sonk)</h3>
          <p>Canada</p>
          <p>Growth</p>
          <br />
          <p>
            <a href="https://twitter.com/Sonk0x" target="_new">
              @Sonk0x
            </a>
          </p>
        </div>
        <div className="team__member">
          <img src={BradfordImage} alt="" />
          <h3>Bradford (Covey)</h3>
          <p>United States</p>
          <p>Social Media</p>
          <br />
          <p>
            <a href="https://twitter.com/cheelon" target="_new">
              @cheelon
            </a>
          </p>
        </div>
      </div>
      {/* <a href="/team" className="team__button">View Full Bios</a> */}
    </div>
  </section>
);

export default Team;
