import * as React from 'react';
import './about.scss';
import minting from '../images/S2-minting-button.png';

const About = () => (
  <section className="about">
    <div className="about__container">
      <div className="about__content">
        <h1>We’re the Cheeky Unts!</h1>
        <p>
          Unts are the most fair dinkum generated NFTs on the Cardano chain!
          They're 100% bogan and cuter than ya Mum's sista!
        </p>
        <h2>
          We’re building a web3 gaming arcade and saving wildlife around the world along the way.
        </h2>
        <p>Second hand Unts &amp; Doggos available on jpg.store</p>
        <h2>
          <a className="about__minting-button" href="https://www.jpg.store/collection/cheekyunts" target="_new">Season One</a>
          {' '}
          ,
          {' '}
          <a className="about__minting-button" href="https://www.jpg.store/collection/season2cheekyunts" target="_new">Season Two</a>
          {' '}
          &amp;
          {' '}
          <a className="about__minting-button" href="https://www.jpg.store/collection/chainsondogs" target="_new">Chains on Dogs</a>
        </h2>
      </div>
      <iframe title="Skate Unt - Arcade Machine" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share width="512" height="512" src="https://sketchfab.com/models/c18d9a2d0942412987d3fcf48204d8a0/embed?autostart=1&transparent=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_annotations=0&ui_theme=dark"> </iframe>
    </div>
  </section>
);

export default About;
