import * as React from 'react';
import PropTypes from 'prop-types';

import './content.scss';

const Content = ({ children }) => (
  <section className="content">
    <div
      className="content__container"
    >
      {children}
    </div>
  </section>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
