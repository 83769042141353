import React from 'react';

import collectionsBackground from '../images/hero-background.gif';
import Content from './content';

import roadtripunt from '../images/collections/S1-280.webp';
import wickedunt from '../images/collections/wicked-169.webp';
import festiveunt from '../images/collections/festive-686.webp';
import roadtriplogo from '../images/collections/cheekunts-s1-logo.webp';
import wickedlogo from '../images/collections/cheekunts-wicked-logo.webp';
import festivelogo from '../images/collections/cheekunts-festive-logo.webp';
import doggo from '../images/collections/chains-on-dogs.webp';
import doggologo from '../images/collections/chains-on-dogs-logo.png';
import season2unt from '../images/collections/season2unt.webp';

import './collections.scss';

const Collections = () => (
  <div
    className="collections"
    style={{
      backgroundImage: `url(${collectionsBackground})`,
    }}
  >
    <Content>
      <div className="collections__content">

        <div className="collections__heading">
          <h1>Cheeky Unt Collections</h1>
          {/* <a href="/explore?season=season-1-road-trip">Explore More</a> */}
        </div>
        <div className="collections__list">
          <a href="https://www.jpg.store/collection/cheekyunts" className="collections__item">
            <img className="collections__item-image" src={roadtripunt} alt="" />
            <div className="collections__item-header">
              <h2>Road Trip</h2>
              <div className="collections__item-price">Mint Price 25 ADA</div>
            </div>
            <img className="collections__item-logo" src={roadtriplogo} alt="" />
            <div className="collections__item-footer">
              <div className="collections__item-footer-item">
                <div>Launched:</div>
                <div>September 2021</div>
              </div>
              <div className="collections__item-footer-item">
                <div>Size:</div>
                <div>4444</div>
              </div>
            </div>
          </a>
          <a className="collections__item" href="https://www.jpg.store/collection/cheekyunts-halloween">
            <img className="collections__item-image" src={wickedunt} alt="" />
            <div className="collections__item-header">
              <h2>Wicked Unts</h2>
              <div className="collections__item-price">Airdrop</div>
            </div>
            <img className="collections__item-logo" src={wickedlogo} alt="" />
            <div className="collections__item-footer">
              <div className="collections__item-footer-item">
                <div>Launched:</div>
                <div>October 2021</div>
              </div>
              <div className="collections__item-footer-item">
                <div>Size:</div>
                <div>222</div>
              </div>
            </div>
          </a>
          <a href="https://www.jpg.store/collection/festiveunts" className="collections__item">
            <img className="collections__item-image" src={festiveunt} alt="" />
            <div className="collections__item-header">
              <h2>Festive Unts</h2>
              <div className="collections__item-price">Mint Price 8 ADA</div>
            </div>
            <img className="collections__item-logo" src={festivelogo} alt="" />
            <div className="collections__item-footer">
              <div className="collections__item-footer-item">
                <div>Launched:</div>
                <div>Dec 2021</div>
              </div>
              <div className="collections__item-footer-item">
                <div>Size:</div>
                <div>1200</div>
              </div>
            </div>
          </a>
          <a href="https://www.jpg.store/collection/season2cheekyunts" className="collections__item">
            <img className="collections__item-image" src={season2unt} alt="" />
            <div className="collections__item-header">
              <h2>Pub Crawl</h2>
              <div className="collections__item-price">Mint Price 55 ADA</div>
            </div>
            <img className="collections__item-logo" src={roadtriplogo} alt="" />
            <div className="collections__item-footer">
              <div className="collections__item-footer-item">
                <div>Launched:</div>
                <div>May 2022</div>
              </div>
              <div className="collections__item-footer-item">
                <div>Size:</div>
                <div>4444</div>
              </div>
            </div>
          </a>
          <a href="https://www.jpg.store/collection/chainsondogs" className="collections__item">
            <img className="collections__item-image" src={doggo} alt="" />
            <div className="collections__item-header">
              <h2>Chains on Dogs</h2>
              <div className="collections__item-price">Free Mint</div>
            </div>
            <img className="collections__item-logo" src={doggologo} alt="" />
            <div className="collections__item-footer">
              <div className="collections__item-footer-item">
                <div>Launched:</div>
                <div>July 2022</div>
              </div>
              <div className="collections__item-footer-item">
                <div>Size:</div>
                <div>1000</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Content>
  </div>
);

export default Collections;
