import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import Content from './content';

import ethermobsBg from '../images/roadmap/ethermobs-bg.jpg';
import changingRoom from '../images/roadmap/swanky2.webp';
import doneIcon from '../images/roadmap/done.svg';
import seasonone from '../images/roadmap/season-one.gif';
import untcoin from '../images/roadmap/unt-coin.png';
import festiveunt from '../images/roadmap/festive-unt.gif';
import wickedunt from '../images/roadmap/wicked-unt.gif';
import pimpmyunt from '../images/roadmap/pimpmyunt.gif';
import steveirwin from '../images/roadmap/steve-irwin.png';
import airdrops from '../images/roadmap/airdrops.png';
import hypeskulls from '../images/roadmap/hype-skulls.gif';
import roadmap from '../images/roadmap/roadmap.png';
import analytics from '../images/roadmap/analytics.png';
import pavia from '../images/roadmap/pavia.png';
import pydano from '../images/roadmap/pydano-snake.gif';
import pubcrawl from '../images/roadmap/global-pub-crawl.gif';
import aeoniumsky from '../images/roadmap/aeoniumsky.gif';
import hopper from '../images/roadmap/hopper.gif';
import skateunt from '../images/roadmap/skate-unt-logo.png';

import './roadmap.scss';

const orange = '#F2994A';
const red = '#EB5757';

const Card = ({
  color,
  icon,
  image,
  heading,
  children,
  status,
  backgroundImage,
  size,
}) => (
  <div
    href="/explore?season=season-1-road-trip"
    style={{
      backgroundImage: backgroundImage && `url(${backgroundImage})`,
      borderColor: color && color,
    }}
    className={classNames('roadmap__item', {
      '-large': size === 'large',
    })}
  >
    <div
      className="roadmap__item-status"
      style={{
        backgroundColor: color && color,
      }}
    >
      {status === 'done' ? <img src={doneIcon} alt="" /> : <p>{status}</p>}
    </div>
    {icon && <img className="roadmap__item-icon" src={icon} alt="" />}
    {image && <img className="roadmap__item-image" src={image} alt="" />}
    <div
      className={classNames('roadmap__item-header', {
        '-no-icon': !icon && !image,
      })}
    >
      <div className="roadmap__item-text">
        <h2>{heading}</h2>
        {children}
      </div>
    </div>
  </div>
);

Card.defaultProps = {
  color: 'green',
  icon: null,
  image: null,
  size: 'normal',
  backgroundImage: null,
};

Card.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.node.isRequired,
  status: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  size: PropTypes.string,
};

const Roadmap = () => (
  <div className="roadmap" id="roadmap">
    <Content>
      <div className="roadmap__content">
        <section className="roadmap__header">
          <div>
            <h1>Roadmap</h1>
            <p>
              Cheeky Unts was founded by
              {' '}
              <a href="https://sketchfab.com/bitgem" target="_new">
                Bitgem
              </a>
              , a game development &amp; art studio with over 15 years of
              {' '}
              industry experience. We've worked directly with game studios and
              mainstream blockchain projects like
              {' '}
              <strong>Decentraland</strong>
              {' '}
              (Vegas City) and
              {' '}
              <strong>The Sandbox</strong>
              .
            </p>
          </div>
          <div className="roadmap__header-image">
            <img src={roadmap} alt="Roadmap" />
          </div>
        </section>
        <section>
          <h1>2021 Oct - Dec</h1>
          <div className="roadmap__list">
            <Card
              icon={seasonone}
              heading="Cheeky Unts - Season One"
              status="done"
            >
              <p>
                Minted 4,444 Cheeky Unts for season one “Road Trip” and donated
                $50,000 AUD.
              </p>
            </Card>
            <Card
              icon={airdrops}
              heading="Airdrops for Holders"
              status="Ongoing"
            >
              <p>
                We’re always rewarding Unt holders with random airdrops, and we
                have no plans to stop!
              </p>
            </Card>
            <Card icon={pimpmyunt} heading="Pimp My Unt" status="Ongoing">
              <p>
                Stay active in our community to score your very own
                fully-customised Unt!
              </p>
            </Card>
            <Card icon={wickedunt} heading="Wicked Unts" status="Airdrop">
              <p>
                Airdropped 222 Wicked Unts to the Cheeky Unts community for
                Halloween, 100% free!
              </p>
            </Card>
            <Card icon={festiveunt} heading="Festive Unts" status="done">
              <p>
                1,200 Festive Unts with 12 special backgrounds. Collect a full
                dodecaptych set!
              </p>
            </Card>
            <Card
              icon={steveirwin}
              heading=" Steve Irwin Day Raffle"
              status="done"
            >
              <p>
                Raffle to raise funds for Steve Irwin Day with tons of prizes
                for our community!
              </p>
            </Card>
            <Card
              heading="Ethermobs x Cheeky Unts"
              status="done"
              backgroundImage={ethermobsBg}
            >
              <p>
                First major collab saw free airdrops for holders of Cheeky Unts
                and Ethermobs NFTs.
              </p>
            </Card>
          </div>
        </section>

        <section>
          <h1>2022 Jan - Jun</h1>
          <div className="roadmap__list">
            <Card status="Done" size="large" image={changingRoom}>
              <h3>
                An exclusive club is about to open for only the most stylish and
                luxurious unts. To be considered “swanky” enough, an unt needs
                to be sporting a limited-edition outfit inspired by the hottest
                designs in the CNFT space.
              </h3>
            </Card>
            <Card
              icon={hypeskulls}
              heading="H.Y.P.E. Skulls x Cheeky Unts"
              status="done"
            >
              <p>
                The first Swanky Unts collaboration will see the emergence of
                HypeUnts!
              </p>
            </Card>
            <Card
              icon={aeoniumsky}
              heading="aeoniumsky x Cheeky Unts"
              status="done"
            >
              <p>
                Round two of Swanky Unts to enter the changeroom will come out
                as aeoniumsky Unts!
              </p>
            </Card>
            <Card
              icon={hopper}
              heading="Happy Hopper x Cheeky Unts"
              status="done"
            >
              <p>You'll be hopping mad if you miss out on a Hopper Unt.</p>
            </Card>
            <Card
              icon={pubcrawl}
              heading="Pub Crawl - Season Two"
              status="done"
            >
              <p>
                Dropping 28th of May, 4444 new Unts from around the globe!
              </p>
            </Card>
          </div>
        </section>

        <section className="roadmap__sandbox-bg">
          <h1>2023 and Beyond</h1>
          <div className="roadmap__sandbox">
            <Card
              color={orange}
              status="Private Alpha"
              size="large"
              image={skateunt}
            >
              <h3>
                Backed by a team with
                {' '}
                <em>legit</em>
                {' '}
                game development
                experience, we're going to build some ripper experiences for you
                Unts!
              </h3>
            </Card>

          </div>
        </section>

        {/* <section>
          <h1>2023 and Beyond</h1>
          <div className="roadmap__list">
            <Card icon={pavia} heading="Pavia" status="In Dev">
              <p>
                We're approved for the Pavia Creator Program. 3D asset incoming!
              </p>
            </Card>
            <Card icon={untcoin} heading="Arcade Token" status="In Dev">
              <p>Our proposed utility token for the Cheeky Unt Arcade!</p>
            </Card>
            <Card heading="Pydano" status="In Dev" icon={pydano}>
              <p>Our open source Python framework for the Cardano CLI.</p>
            </Card>
            <Card
              color={red}
              icon={analytics}
              heading="CNFT Analytics"
              status="Exploring"
            >
              <p>
                Tools for the CNFT collector, monitor trends, new mints and
                value your portfolio.
              </p>
            </Card>
          </div>
        </section> */}
      </div>
    </Content>
  </div>
);

export default Roadmap;
