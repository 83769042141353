import * as React from 'react';

import Content from './content';

import jimmie from '../images/our_mission/jimmie-jams.jpg';
import karri from '../images/our_mission/karri.jpg';
import mittens from '../images/our_mission/mr-mittens.jpg';
import akawclogo from '../images/our_mission/akawc.png';
import wombatlogo from '../images/our_mission/wombat-awareness.png';
import irwincoin from '../images/our_mission/steve_irwin_coin.png';

import './mission.scss';

const Airdrops = () => (
  <div className="mission">
    <Content>
      <div className="mission__content">
        <div className="mission__description">
          <h1 className="mission__heading">Our Mission</h1>
          <h2>Save Fluffy and Scaly Wildlife</h2>
          <p><a href="https://docs.cheekyunts.com/cheekyunts/press-releases/charity-driven-nft-project-raises-usd50-000+-aud-for-wildlife-conservation">Our donations</a></p>
          <h2>Play for Fun, Play to Win</h2>
          <p>Games Arcade</p>
        </div>
        <div className="mission__examples">
          <div className="mission__card">
            <img src={karri} alt="" />
            <div className="mission__card-content">
              <h1>Adopted Koala - Karri</h1>
              <div className="mission__card-heading">Australian Koala Foundation</div>
              <div className="mission__card-date">1st October  2021</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={jimmie} alt="" />
            <div className="mission__card-content">
              <h1>Adopted Koala - Jimmie</h1>
              <div className="mission__card-heading">Australian Koala Foundation</div>
              <div className="mission__card-date">1st October 2021</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={mittens} alt="" />
            <div className="mission__card-content">
              <h1>Adopted Wombat - Mr Mittens</h1>
              <div className="mission__card-heading">Wombat Awareness Organisation</div>
              <div className="mission__card-date">14th October 2021</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={akawclogo} alt="" />
            <div className="mission__card-content" href="test">
              <h1 className="mission__gold">Donated $25,000 AUD</h1>
              <div className="mission__card-heading">Australian Koala Foundation</div>
              <div className="mission__card-date">5th November 2021</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={wombatlogo} alt="" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Donated $25,000 AUD</h1>
              <div className="mission__card-heading">Wombat Awareness Organisation</div>
              <div className="mission__card-date">5th November 2021</div>
            </div>
          </div>
          <div className="mission__card">
            <img src={irwincoin} alt="" />
            <div className="mission__card-content">
              <h1 className="mission__gold">Donated $2,500 AUD</h1>
              <div className="mission__card-heading">Wildlife Warriors, in honor of Steve Irwin Day</div>
              <div className="mission__card-date">15th November 2021</div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  </div>
);

export default Airdrops;
